<template lang="pug">
.container-fluid.px-3
  .row.mb-4
    .col
      om-heading.mt-1(h1) {{ $t('experimental.heading') }}
  .row(v-if="loading")
    .col
      LoadingTransition
  template(v-else)
    .row(v-if="isSuperAdmin")
      .col
        .experiment-wrapper.p-3.pb-4.mb-5
          transition(name="fade")
            nonstandard-order-domains
    .row(v-if="hasFeeds")
      .col
        .experiment-wrapper.p-3.pb-4.mb-5
          transition(name="fade")
            feeds
    .row(v-if="isSuperAdmin")
      .col
        .experiment-wrapper.p-3.pb-4.mb-5
          transition(name="fade")
            smart-product-recommendation
    .row(v-if="hasPPO")
      .col
        .experiment-wrapper.p-3.pb-4.mb-5
          transition(name="fade")
            product-page-optimizer
    .row(v-if="isSuperAdmin")
      .col
        .experiment-wrapper.p-3.pb-4.mb-5
          transition(name="fade")
            domain-context
    .row(v-if="isSuperAdmin")
      .col
        .experiment-wrapper.p-3.pb-4.mb-5
          transition(name="fade")
            smart-personalization
    .row(v-for="experiment in experiments" :key="experiment")
      .col
        .experiment-wrapper.p-3.pb-4.mb-5
          transition(name="fade")
            component(
              :is="experiment"
              :data="experimentsData[experiment]"
              @add="onAdd({ type: experiment, data: $event })"
              @update="onUpdate({ type: experiment, id: $event.id, data: $event })"
            )
    .row
      .col
        .experiment-wrapper.p-3.pb-4.mb-5
          transition(name="fade")
            BrowserTabNotification(
              :data="experimentsData['BrowserTabNotification']"
              @add="onAdd({ type: 'BrowserTabNotification', data: $event })"
              @update="onUpdate({ type: 'BrowserTabNotification', id: $event.id, data: $event })"
            )
</template>

<script>
  import { mapGetters, mapState } from 'vuex';
  import ADD_EXPERIMENTAL_SETTING from '@/graphql/AddExperimentalSetting.gql';
  import UPDATE_EXPERIMENTAL_SETTING from '@/graphql/UpdateExperimentalSetting.gql';
  import GET_EXPERIMENTAL_SETTING from '@/graphql/GetExperimentalSettings.gql';
  import { isPPOEnabled } from '@/utils/features';
  import Experiments from '../Experimental';

  const EXPERIMENT_FLAGS_PREFIX = 'EXPERIMENT_';

  export default {
    components: {
      LoadingTransition: () => import('@/components/LoadingTransition.vue'),
      BrowserTabNotification: () =>
        import('@/components/AccountSettings/BrowserTabNotification.vue'),
      ...Experiments,
    },
    computed: {
      ...mapGetters(['isSuperAdmin']),
      ...mapState(['account']),
      features() {
        return this.account && this.account.features ? this.account.features : [];
      },
      loading() {
        return this.$apollo.queries.experimentsData.loading;
      },
      experiments() {
        return this.features
          .filter((feature) => feature.startsWith(EXPERIMENT_FLAGS_PREFIX))
          .map((experiment) =>
            experiment
              .replace(EXPERIMENT_FLAGS_PREFIX, '')
              .split('_')
              .map((namePiece) => `${namePiece.charAt(0)}${namePiece.slice(1).toLowerCase()}`)
              .join(''),
          );
      },
      hasPPO() {
        return isPPOEnabled(this.features) && this.isSuperAdmin;
      },
      hasFeeds() {
        return this.isSuperAdmin;
      },
    },
    async mounted() {
      this.mapFeaturesToExperiments();
      this.$bus.$on('reFetechExperimentalSettings', async () => {
        await this.$apollo.queries.experimentsData.refetch();
      });
    },
    methods: {
      mapFeaturesToExperiments() {},
      async onAdd(data) {
        try {
          await this.$apollo.mutate({
            mutation: ADD_EXPERIMENTAL_SETTING,
            variables: {
              input: data,
            },
          });
          await this.$apollo.queries.experimentsData.refetch();
        } catch (e) {
          console.log('eerror', e);
        }
      },
      async onUpdate(data) {
        try {
          if (!data.id) {
            throw new Error('update called without id.');
          }
          await this.$apollo.mutate({
            mutation: UPDATE_EXPERIMENTAL_SETTING,
            variables: {
              input: data,
            },
          });
          await this.$apollo.queries.experimentsData.refetch();
        } catch (e) {
          console.log('eerror', e);
        }
      },
    },
    apollo: {
      experimentsData: {
        query: GET_EXPERIMENTAL_SETTING,
      },
    },
  };
</script>

<style lang="sass">
  @import '../../sass/variables/_colors.sass'

  .experiment-wrapper
    background: $om-light-grey-3
    border-radius: 7px
</style>
